
import { computed } from '@vue/runtime-core'
import store from '../../../store'
import { Task } from '@/lib/apis'
export default {
  setup() {
    return {
      queue: computed(() =>
        !store.state.Queue
          ? []
          : store.state.Queue.filter(
              (a: Task): boolean =>
                a.state === 'benchmark' || a.state === 'waiting'
            )
      )
    }
  }
}
