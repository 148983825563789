/* tslint:disable */
/* eslint-disable */
/**
 * Piscon-portal API
 * this is a api docment of piscon-portal.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Instance
 */
export interface Instance {
    /**
     * 
     * @type {number}
     * @memberof Instance
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Instance
     */
    team_id: number;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    grobal_ip_address?: string;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    private_ip_address?: string;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    password?: string;
    /**
     * 
     * @type {number}
     * @memberof Instance
     */
    instance_number: number;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    instance_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    name: string;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    result_id: number;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    text?: string;
}
/**
 * 
 * @export
 * @interface PkceParams
 */
export interface PkceParams {
    /**
     * 
     * @type {string}
     * @memberof PkceParams
     */
    code_challenge: string;
    /**
     * 
     * @type {string}
     * @memberof PkceParams
     */
    code_challenge_method: string;
    /**
     * 
     * @type {string}
     * @memberof PkceParams
     */
    code_verifier: string;
    /**
     * 
     * @type {string}
     * @memberof PkceParams
     */
    client_id: string;
    /**
     * 
     * @type {string}
     * @memberof PkceParams
     */
    response_type: string;
}
/**
 * 
 * @export
 * @interface PostBenchmarkRequest
 */
export interface PostBenchmarkRequest {
    /**
     * 
     * @type {string}
     * @memberof PostBenchmarkRequest
     */
    betterize?: string;
}
/**
 * 
 * @export
 * @interface PostTeamRequest
 */
export interface PostTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof PostTeamRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PostTeamRequest
     */
    group?: string;
}
/**
 * 
 * @export
 * @interface Questions
 */
export interface Questions {
    /**
     * 
     * @type {string}
     * @memberof Questions
     */
    question?: string;
    /**
     * 
     * @type {string}
     * @memberof Questions
     */
    answer?: string;
}
/**
 * 
 * @export
 * @interface Response
 */
export interface Response {
    /**
     * 
     * @type {boolean}
     * @memberof Response
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof Response
     */
    message: string;
}
/**
 * 
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * 
     * @type {number}
     * @memberof Result
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Result
     */
    team_id: number;
    /**
     * 
     * @type {number}
     * @memberof Result
     */
    task_id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Result
     */
    pass: boolean;
    /**
     * 
     * @type {number}
     * @memberof Result
     */
    score: number;
    /**
     * 
     * @type {number}
     * @memberof Result
     */
    capmpaign?: number;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    betterize?: string;
    /**
     * 
     * @type {Array<Message>}
     * @memberof Result
     */
    messages: Array<Message>;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    created_at: string;
}
/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    cmd_str?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    ip?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    betterize?: string;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    team_id: number;
    /**
     * 
     * @type {Team}
     * @memberof Task
     */
    team: Team;
}
/**
 * 
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    ID: number;
    /**
     * team name
     * @type {string}
     * @memberof Team
     */
    name: string;
    /**
     * 
     * @type {Array<Instance>}
     * @memberof Team
     */
    instance: Array<Instance>;
    /**
     * 
     * @type {Array<Result>}
     * @memberof Team
     */
    results: Array<Result>;
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    max_instance_number: number;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    group: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    screen_name: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    team_id: number;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get All team description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTeamGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get All team description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTeamGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Team>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTeamGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Get All team description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTeamGet(options?: any): AxiosPromise<Array<Team>> {
            return localVarFp.adminTeamGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary Get All team description
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminTeamGet(options?: any) {
        return AdminApiFp(this.configuration).adminTeamGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCallbackGet: async (code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('authCallbackGet', 'code', code)
            const localVarPath = `/auth/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate codeVerifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCodePost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authCallbackGet(code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authCallbackGet(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate codeVerifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authCodePost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PkceParams>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authCodePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCallbackGet(code: string, options?: any): AxiosPromise<void> {
            return localVarFp.authCallbackGet(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate codeVerifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCodePost(options?: any): AxiosPromise<PkceParams> {
            return localVarFp.authCodePost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authCallbackGet(code: string, options?: any) {
        return AuthApiFp(this.configuration).authCallbackGet(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate codeVerifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authCodePost(options?: any) {
        return AuthApiFp(this.configuration).authCodePost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BenchApi - axios parameter creator
 * @export
 */
export const BenchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns an array of task model
         * @summary Get bench queue data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        benchmarkQueueGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/benchmark/queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Queue benchmark task
         * @param {number} teamId 
         * @param {number} instanceNumber current instance number
         * @param {PostBenchmarkRequest} [postBenchmarkRequest] betterize (Sorry, but I dont understand)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        benchmarkTeamIdInstanceNumberPost: async (teamId: number, instanceNumber: number, postBenchmarkRequest?: PostBenchmarkRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('benchmarkTeamIdInstanceNumberPost', 'teamId', teamId)
            // verify required parameter 'instanceNumber' is not null or undefined
            assertParamExists('benchmarkTeamIdInstanceNumberPost', 'instanceNumber', instanceNumber)
            const localVarPath = `/benchmark/{team_id}/{instance_number}`
                .replace(`{${"team_id"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"instance_number"}}`, encodeURIComponent(String(instanceNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postBenchmarkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BenchApi - functional programming interface
 * @export
 */
export const BenchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BenchApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns an array of task model
         * @summary Get bench queue data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async benchmarkQueueGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Task>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.benchmarkQueueGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Queue benchmark task
         * @param {number} teamId 
         * @param {number} instanceNumber current instance number
         * @param {PostBenchmarkRequest} [postBenchmarkRequest] betterize (Sorry, but I dont understand)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async benchmarkTeamIdInstanceNumberPost(teamId: number, instanceNumber: number, postBenchmarkRequest?: PostBenchmarkRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.benchmarkTeamIdInstanceNumberPost(teamId, instanceNumber, postBenchmarkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BenchApi - factory interface
 * @export
 */
export const BenchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BenchApiFp(configuration)
    return {
        /**
         * Returns an array of task model
         * @summary Get bench queue data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        benchmarkQueueGet(options?: any): AxiosPromise<Array<Task>> {
            return localVarFp.benchmarkQueueGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Queue benchmark task
         * @param {number} teamId 
         * @param {number} instanceNumber current instance number
         * @param {PostBenchmarkRequest} [postBenchmarkRequest] betterize (Sorry, but I dont understand)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        benchmarkTeamIdInstanceNumberPost(teamId: number, instanceNumber: number, postBenchmarkRequest?: PostBenchmarkRequest, options?: any): AxiosPromise<Response> {
            return localVarFp.benchmarkTeamIdInstanceNumberPost(teamId, instanceNumber, postBenchmarkRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BenchApi - object-oriented interface
 * @export
 * @class BenchApi
 * @extends {BaseAPI}
 */
export class BenchApi extends BaseAPI {
    /**
     * Returns an array of task model
     * @summary Get bench queue data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchApi
     */
    public benchmarkQueueGet(options?: any) {
        return BenchApiFp(this.configuration).benchmarkQueueGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Queue benchmark task
     * @param {number} teamId 
     * @param {number} instanceNumber current instance number
     * @param {PostBenchmarkRequest} [postBenchmarkRequest] betterize (Sorry, but I dont understand)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchApi
     */
    public benchmarkTeamIdInstanceNumberPost(teamId: number, instanceNumber: number, postBenchmarkRequest?: PostBenchmarkRequest, options?: any) {
        return BenchApiFp(this.configuration).benchmarkTeamIdInstanceNumberPost(teamId, instanceNumber, postBenchmarkRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns an array of task model
         * @summary Get bench queue data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        benchmarkQueueGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/benchmark/queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get user data from traq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get group data from traq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meGroupGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/me/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an array of team model
         * @summary Get top team data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newerGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/newer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an array of question model
         * @summary Get question data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an array of team model
         * @summary Get all result data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns an array of task model
         * @summary Get bench queue data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async benchmarkQueueGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Task>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.benchmarkQueueGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get user data from traq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get group data from traq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meGroupGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meGroupGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns an array of team model
         * @summary Get top team data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newerGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Team>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns an array of question model
         * @summary Get question data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Questions>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns an array of team model
         * @summary Get all result data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resultsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Team>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resultsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Returns an array of task model
         * @summary Get bench queue data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        benchmarkQueueGet(options?: any): AxiosPromise<Array<Task>> {
            return localVarFp.benchmarkQueueGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get user data from traq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meGet(options?: any): AxiosPromise<User> {
            return localVarFp.meGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get group data from traq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meGroupGet(options?: any): AxiosPromise<string> {
            return localVarFp.meGroupGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an array of team model
         * @summary Get top team data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newerGet(options?: any): AxiosPromise<Array<Team>> {
            return localVarFp.newerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an array of question model
         * @summary Get question data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsGet(options?: any): AxiosPromise<Array<Questions>> {
            return localVarFp.questionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an array of team model
         * @summary Get all result data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultsGet(options?: any): AxiosPromise<Array<Team>> {
            return localVarFp.resultsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Returns an array of task model
     * @summary Get bench queue data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public benchmarkQueueGet(options?: any) {
        return DefaultApiFp(this.configuration).benchmarkQueueGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get user data from traq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public meGet(options?: any) {
        return DefaultApiFp(this.configuration).meGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get group data from traq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public meGroupGet(options?: any) {
        return DefaultApiFp(this.configuration).meGroupGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an array of team model
     * @summary Get top team data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public newerGet(options?: any) {
        return DefaultApiFp(this.configuration).newerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an array of question model
     * @summary Get question data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public questionsGet(options?: any) {
        return DefaultApiFp(this.configuration).questionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an array of team model
     * @summary Get all result data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public resultsGet(options?: any) {
        return DefaultApiFp(this.configuration).resultsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InstanceApi - axios parameter creator
 * @export
 */
export const InstanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete instance
         * @summary Delete instance
         * @param {number} teamId team id
         * @param {number} instanceNumber current instance number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        instanceTeamIdInstanceNumberDelete: async (teamId: number, instanceNumber: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('instanceTeamIdInstanceNumberDelete', 'teamId', teamId)
            // verify required parameter 'instanceNumber' is not null or undefined
            assertParamExists('instanceTeamIdInstanceNumberDelete', 'instanceNumber', instanceNumber)
            const localVarPath = `/instance/{team_id}/{instance_number}`
                .replace(`{${"team_id"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"instance_number"}}`, encodeURIComponent(String(instanceNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Start new instance but its numbers are restricted by admin
         * @summary Start new instance
         * @param {number} teamId team id
         * @param {number} instanceNumber current instance number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        instanceTeamIdInstanceNumberPost: async (teamId: number, instanceNumber: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('instanceTeamIdInstanceNumberPost', 'teamId', teamId)
            // verify required parameter 'instanceNumber' is not null or undefined
            assertParamExists('instanceTeamIdInstanceNumberPost', 'instanceNumber', instanceNumber)
            const localVarPath = `/instance/{team_id}/{instance_number}`
                .replace(`{${"team_id"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"instance_number"}}`, encodeURIComponent(String(instanceNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstanceApi - functional programming interface
 * @export
 */
export const InstanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstanceApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete instance
         * @summary Delete instance
         * @param {number} teamId team id
         * @param {number} instanceNumber current instance number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async instanceTeamIdInstanceNumberDelete(teamId: number, instanceNumber: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.instanceTeamIdInstanceNumberDelete(teamId, instanceNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Start new instance but its numbers are restricted by admin
         * @summary Start new instance
         * @param {number} teamId team id
         * @param {number} instanceNumber current instance number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async instanceTeamIdInstanceNumberPost(teamId: number, instanceNumber: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Instance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.instanceTeamIdInstanceNumberPost(teamId, instanceNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InstanceApi - factory interface
 * @export
 */
export const InstanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstanceApiFp(configuration)
    return {
        /**
         * Delete instance
         * @summary Delete instance
         * @param {number} teamId team id
         * @param {number} instanceNumber current instance number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        instanceTeamIdInstanceNumberDelete(teamId: number, instanceNumber: number, options?: any): AxiosPromise<void> {
            return localVarFp.instanceTeamIdInstanceNumberDelete(teamId, instanceNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * Start new instance but its numbers are restricted by admin
         * @summary Start new instance
         * @param {number} teamId team id
         * @param {number} instanceNumber current instance number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        instanceTeamIdInstanceNumberPost(teamId: number, instanceNumber: number, options?: any): AxiosPromise<Instance> {
            return localVarFp.instanceTeamIdInstanceNumberPost(teamId, instanceNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstanceApi - object-oriented interface
 * @export
 * @class InstanceApi
 * @extends {BaseAPI}
 */
export class InstanceApi extends BaseAPI {
    /**
     * Delete instance
     * @summary Delete instance
     * @param {number} teamId team id
     * @param {number} instanceNumber current instance number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceApi
     */
    public instanceTeamIdInstanceNumberDelete(teamId: number, instanceNumber: number, options?: any) {
        return InstanceApiFp(this.configuration).instanceTeamIdInstanceNumberDelete(teamId, instanceNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Start new instance but its numbers are restricted by admin
     * @summary Start new instance
     * @param {number} teamId team id
     * @param {number} instanceNumber current instance number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceApi
     */
    public instanceTeamIdInstanceNumberPost(teamId: number, instanceNumber: number, options?: any) {
        return InstanceApiFp(this.configuration).instanceTeamIdInstanceNumberPost(teamId, instanceNumber, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuestionsApi - axios parameter creator
 * @export
 */
export const QuestionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns an array of question model
         * @summary Get question data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Answer question
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questionsIdDelete', 'id', id)
            const localVarPath = `/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Answer question
         * @param {number} id 
         * @param {Questions} [questions] answer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsIdPut: async (id: number, questions?: Questions, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questionsIdPut', 'id', id)
            const localVarPath = `/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post new question
         * @param {Questions} [questions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsPost: async (questions?: Questions, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionsApi - functional programming interface
 * @export
 */
export const QuestionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns an array of question model
         * @summary Get question data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Questions>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Answer question
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionsIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Questions>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Answer question
         * @param {number} id 
         * @param {Questions} [questions] answer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionsIdPut(id: number, questions?: Questions, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Questions>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionsIdPut(id, questions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post new question
         * @param {Questions} [questions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionsPost(questions?: Questions, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionsPost(questions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionsApi - factory interface
 * @export
 */
export const QuestionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionsApiFp(configuration)
    return {
        /**
         * Returns an array of question model
         * @summary Get question data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsGet(options?: any): AxiosPromise<Array<Questions>> {
            return localVarFp.questionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Answer question
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsIdDelete(id: number, options?: any): AxiosPromise<Array<Questions>> {
            return localVarFp.questionsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Answer question
         * @param {number} id 
         * @param {Questions} [questions] answer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsIdPut(id: number, questions?: Questions, options?: any): AxiosPromise<Array<Questions>> {
            return localVarFp.questionsIdPut(id, questions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post new question
         * @param {Questions} [questions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsPost(questions?: Questions, options?: any): AxiosPromise<void> {
            return localVarFp.questionsPost(questions, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionsApi - object-oriented interface
 * @export
 * @class QuestionsApi
 * @extends {BaseAPI}
 */
export class QuestionsApi extends BaseAPI {
    /**
     * Returns an array of question model
     * @summary Get question data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionsApi
     */
    public questionsGet(options?: any) {
        return QuestionsApiFp(this.configuration).questionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Answer question
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionsApi
     */
    public questionsIdDelete(id: number, options?: any) {
        return QuestionsApiFp(this.configuration).questionsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Answer question
     * @param {number} id 
     * @param {Questions} [questions] answer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionsApi
     */
    public questionsIdPut(id: number, questions?: Questions, options?: any) {
        return QuestionsApiFp(this.configuration).questionsIdPut(id, questions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post new question
     * @param {Questions} [questions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionsApi
     */
    public questionsPost(questions?: Questions, options?: any) {
        return QuestionsApiFp(this.configuration).questionsPost(questions, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeamApi - axios parameter creator
 * @export
 */
export const TeamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get All team description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTeamGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get team description
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('teamIdGet', 'id', id)
            const localVarPath = `/team/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary チームのインスタンス情報を更新する
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamIdInstancesPut: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('teamIdInstancesPut', 'id', id)
            const localVarPath = `/team/{id}/instances`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get team member
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamIdMemberGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('teamIdMemberGet', 'id', id)
            const localVarPath = `/team/{id}/member`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an new Team model
         * @summary Create team
         * @param {PostTeamRequest} [postTeamRequest] Team to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamPost: async (postTeamRequest?: PostTeamRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamApi - functional programming interface
 * @export
 */
export const TeamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get All team description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTeamGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Team>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTeamGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get team description
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary チームのインスタンス情報を更新する
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamIdInstancesPut(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Instance>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamIdInstancesPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get team member
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamIdMemberGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamIdMemberGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns an new Team model
         * @summary Create team
         * @param {PostTeamRequest} [postTeamRequest] Team to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamPost(postTeamRequest?: PostTeamRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamPost(postTeamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamApi - factory interface
 * @export
 */
export const TeamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamApiFp(configuration)
    return {
        /**
         * 
         * @summary Get All team description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTeamGet(options?: any): AxiosPromise<Array<Team>> {
            return localVarFp.adminTeamGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get team description
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamIdGet(id: number, options?: any): AxiosPromise<Team> {
            return localVarFp.teamIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary チームのインスタンス情報を更新する
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamIdInstancesPut(id: number, options?: any): AxiosPromise<Array<Instance>> {
            return localVarFp.teamIdInstancesPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get team member
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamIdMemberGet(id: number, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.teamIdMemberGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an new Team model
         * @summary Create team
         * @param {PostTeamRequest} [postTeamRequest] Team to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamPost(postTeamRequest?: PostTeamRequest, options?: any): AxiosPromise<Team> {
            return localVarFp.teamPost(postTeamRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamApi - object-oriented interface
 * @export
 * @class TeamApi
 * @extends {BaseAPI}
 */
export class TeamApi extends BaseAPI {
    /**
     * 
     * @summary Get All team description
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public adminTeamGet(options?: any) {
        return TeamApiFp(this.configuration).adminTeamGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get team description
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public teamIdGet(id: number, options?: any) {
        return TeamApiFp(this.configuration).teamIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary チームのインスタンス情報を更新する
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public teamIdInstancesPut(id: number, options?: any) {
        return TeamApiFp(this.configuration).teamIdInstancesPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get team member
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public teamIdMemberGet(id: number, options?: any) {
        return TeamApiFp(this.configuration).teamIdMemberGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an new Team model
     * @summary Create team
     * @param {PostTeamRequest} [postTeamRequest] Team to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public teamPost(postTeamRequest?: PostTeamRequest, options?: any) {
        return TeamApiFp(this.configuration).teamPost(postTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get user description
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userNameGet: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('userNameGet', 'name', name)
            const localVarPath = `/user/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new user
         * @summary Create User
         * @param {User} [user] User to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPost: async (user?: User, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get user description
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userNameGet(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userNameGet(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create new user
         * @summary Create User
         * @param {User} [user] User to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPost(user?: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Get user description
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userNameGet(name: string, options?: any): AxiosPromise<User> {
            return localVarFp.userNameGet(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Create new user
         * @summary Create User
         * @param {User} [user] User to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPost(user?: User, options?: any): AxiosPromise<User> {
            return localVarFp.userPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Get user description
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userNameGet(name: string, options?: any) {
        return UserApiFp(this.configuration).userNameGet(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create new user
     * @summary Create User
     * @param {User} [user] User to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPost(user?: User, options?: any) {
        return UserApiFp(this.configuration).userPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}

export class Apis extends BaseAPI {
    /**
     *
     * @summary Get All team description
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public adminTeamGet(options?: any) {
        return TeamApiFp(this.configuration).adminTeamGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authCallbackGet(code: string, options?: any) {
        return AuthApiFp(this.configuration).authCallbackGet(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Generate codeVerifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authCodePost(options?: any) {
        return AuthApiFp(this.configuration).authCodePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an array of task model
     * @summary Get bench queue data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public benchmarkQueueGet(options?: any) {
        return DefaultApiFp(this.configuration).benchmarkQueueGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Queue benchmark task
     * @param {number} teamId
     * @param {number} instanceNumber current instance number
     * @param {PostBenchmarkRequest} [postBenchmarkRequest] betterize (Sorry, but I dont understand)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchApi
     */
    public benchmarkTeamIdInstanceNumberPost(teamId: number, instanceNumber: number, postBenchmarkRequest?: PostBenchmarkRequest, options?: any) {
        return BenchApiFp(this.configuration).benchmarkTeamIdInstanceNumberPost(teamId, instanceNumber, postBenchmarkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary get user data from traq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public meGet(options?: any) {
        return DefaultApiFp(this.configuration).meGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary get group data from traq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public meGroupGet(options?: any) {
        return DefaultApiFp(this.configuration).meGroupGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an array of team model
     * @summary Get top team data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public newerGet(options?: any) {
        return DefaultApiFp(this.configuration).newerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an array of question model
     * @summary Get question data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionsApi
     */
    public questionsGet(options?: any) {
        return QuestionsApiFp(this.configuration).questionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an array of team model
     * @summary Get all result data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public resultsGet(options?: any) {
        return DefaultApiFp(this.configuration).resultsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete instance
     * @summary Delete instance
     * @param {number} teamId team id
     * @param {number} instanceNumber current instance number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceApi
     */
    public instanceTeamIdInstanceNumberDelete(teamId: number, instanceNumber: number, options?: any) {
        return InstanceApiFp(this.configuration).instanceTeamIdInstanceNumberDelete(teamId, instanceNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Start new instance but its numbers are restricted by admin
     * @summary Start new instance
     * @param {number} teamId team id
     * @param {number} instanceNumber current instance number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceApi
     */
    public instanceTeamIdInstanceNumberPost(teamId: number, instanceNumber: number, options?: any) {
        return InstanceApiFp(this.configuration).instanceTeamIdInstanceNumberPost(teamId, instanceNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Answer question
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionsApi
     */
    public questionsIdDelete(id: number, options?: any) {
        return QuestionsApiFp(this.configuration).questionsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Answer question
     * @param {number} id
     * @param {Questions} [questions] answer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionsApi
     */
    public questionsIdPut(id: number, questions?: Questions, options?: any) {
        return QuestionsApiFp(this.configuration).questionsIdPut(id, questions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Post new question
     * @param {Questions} [questions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionsApi
     */
    public questionsPost(questions?: Questions, options?: any) {
        return QuestionsApiFp(this.configuration).questionsPost(questions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get team description
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public teamIdGet(id: number, options?: any) {
        return TeamApiFp(this.configuration).teamIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary チームのインスタンス情報を更新する
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public teamIdInstancesPut(id: number, options?: any) {
        return TeamApiFp(this.configuration).teamIdInstancesPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary get team member
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public teamIdMemberGet(id: number, options?: any) {
        return TeamApiFp(this.configuration).teamIdMemberGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an new Team model
     * @summary Create team
     * @param {PostTeamRequest} [postTeamRequest] Team to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public teamPost(postTeamRequest?: PostTeamRequest, options?: any) {
        return TeamApiFp(this.configuration).teamPost(postTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get user description
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userNameGet(name: string, options?: any) {
        return UserApiFp(this.configuration).userNameGet(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create new user
     * @summary Create User
     * @param {User} [user] User to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPost(user?: User, options?: any) {
        return UserApiFp(this.configuration).userPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}
